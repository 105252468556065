<template>
  <div class="resources-detail-page safe-area-inset-bottom">
    <div class="resources-item">
      <div class="item-inner-box ">
        <div class="item-box">
          <div class="dfr main-between">
            <div class="dfr">
              <!-- 头像 -->
              <div class="item-portrait-box">
                <!-- 用户头像 -->
                <img
                  v-if="detail.avatar"
                  :src="detail.avatar"
                  alt=""
                  class="w100 h100 object-cover"
                />
                <!-- 默认头像 -->
                <img
                  v-else
                  src="../assets/images/default-portrait.png"
                  alt=""
                  class="w100 h100"
                />
                <!-- todo 徽章 -->
                <div
                  v-if="detail.is_auth || detail.is_major_auth"
                  class="item-badge-box"
                >
                  <img
                    src="../assets/images/home-emblem.png"
                    alt=""
                    class="w100 h100"
                  />
                </div>
              </div>
              <!-- 信息 -->
              <div class="item-message dfc flex1">
                <div class="dfr cross-center">
                  <div class="dfr cross-center">
                    <!-- 姓名 -->
                    <div class="fs32 fw600 fc333">{{ detail.name }}</div>
                  </div>
                  <!-- todo 火焰标志 -->
                  <div v-if="detail.is_recommend" class="is-hot ml16">
                    <img
                      src="../assets/images/hot.png"
                      class="w100 h100"
                      alt=""
                    />
                  </div>
                </div>
                <div class="mt12 item-com-job">
                  <div class="fs24 fc666 item-job">{{ detail.job }}</div>
                  <div class="line ml12 mr12 item-job"></div>
                  <div class="fs24 fc666 item-company">
                    {{ detail.company }}
                  </div>
                </div>
                <div class="mt8 fs24 scale" v-if="detail.scale">
                  {{ detail.scale.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 领域 -->
        <div class="fs24 fieldBox" v-if="detail.major_field">
          # {{ detail.major_field.name }} #
        </div>
      </div>
    </div>

    <!-- 公司介绍 -->
    <div
      class="mt20 bgfff company-detail"
      v-if="
        detail.company_service ||
          detail.company_ability ||
          (detail.service_cases && detail.service_cases.length)
      "
    >
      <!-- 公司服务 -->
      <div class="dfc" v-if="detail.company_service">
        <div class="dfr cross-center">
          <app-title title="公司服务"></app-title>
          <div class="fs28 fc666 ml12">(一句话介绍)</div>
        </div>
        <div class="fs28 serve-color mt36 ml22">
          {{ detail.company_service }}
        </div>
      </div>
      <!-- 公司核心能力 -->
      <div class="dfc mt80" v-if="detail.company_ability">
        <div class="dfr cross-center">
          <app-title title="公司核心能力"></app-title>
        </div>
        <div class="fs28 mt36 ml22">{{ detail.company_ability }}</div>
      </div>
      <!-- todo 服务案例 -->
      <div
        class="dfc mt80"
        v-if="detail.service_cases && detail.service_cases.length"
      >
        <div class="dfr cross-center">
          <app-title title="服务案例"></app-title>
          <div class="fs28 fc666 ml12">(代表性前三)</div>
        </div>
        <div class="case-list-box">
          <div
            v-for="(item, index) in detail.service_cases"
            :key="index"
            class="case-list"
          >
            <div class="dfr cross-center">
              <div class="fs0 title-icon">
                <img
                  src="../assets/images/shape.png"
                  alt=""
                  class="w100 h100"
                />
              </div>
              <div class="fs28 fc333 fb ml16">{{ item.title }}</div>
            </div>
            <div class="mt16 ml28 fs26 fc666">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- button -->
    <div class="b-button-box">
      <div class="b-button bgfff">
        <icy-button height="0.8rem" @click="sendMessage">
          <div class="fs28 fb b-text-color">把我的联系信息发给 TA</div>
        </icy-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      detail: {},
      userId: ""
    };
  },
  created() {
    this.userId = parseInt(this.$route.query.id) || "";
    this.$title("资源信息详情");
    window.scrollTo(0, 0);
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {
      this.getInfo();
    },
    async getInfo() {
      const data = {
        user_id: this.userId
      };
      const resp = await this.$API
        .get("resourcesDetail", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        // todo

        this.detail = resp.data;
      }
    },
    // TODO fa信息
    async sendMessage() {
      const { phone } = this.detail;
      // let phone = 15821137673;
      const resp = await this.$API
        .post("sendMajor", { phone })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast("发送成功");
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.resources-detail-page {
  background: #f7f7f7;
  min-height: 100vh;
  .resources-item {
    .item-inner-box {
      width: 7.5rem;
      background: #ffffff;
      border-radius: 0.12rem;
      box-sizing: border-box;
      overflow: hidden;
    }
    .item-box {
      padding: 0.36rem 0 0.36rem 0.36rem;

      .item-portrait-box {
        width: 0.96rem;
        height: 0.96rem;
        position: relative;
        img {
          border-radius: 50%;
          object-fit: cover;
        }
        .item-badge-box {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            position: absolute;
            object-fit: cover;
          }
        }
      }
      .item-message {
        margin-left: 0.2rem;
        .is-hot {
          width: 0.24rem;
          height: 0.28rem;
        }
        .item-com-job {
          white-space: normal;
          word-break: break-all;
          .line {
            width: 0.02rem;
            height: 0.2rem;
            background: #cccccc;
          }
          .item-job {
            display: inline-block;
          }
          .item-company {
            display: inline;
          }
        }
        .scale {
          color: #b2b2b2;
        }
      }
      .item-delete {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.36rem;
        img {
          object-fit: cover;
        }
      }
    }
    .description {
      width: 6.78rem;
      margin: 0.36rem auto 0;
    }
    .fieldBox {
      border-top: 0.01rem solid #eee;
      width: 6.78rem;
      margin: 0 auto;
      color: #b2b2b2;
      padding: 0.26rem 0;
    }
  }
  .company-detail {
    padding: 0.36rem;
    .serve-color {
      color: #a3782a;
    }
    .case-list-box {
      .case-list {
        margin-top: 0.48rem;
        .title-icon {
          width: 0.12rem;
          height: 0.12rem;
        }
      }
      .case-list:first-child {
        margin-top: 0.36rem;
      }
    }
  }
  .b-button-box {
    height: calc(1.2rem + env(safe-area-inset-bottom));
    height: calc(1.2rem + constant(safe-area-inset-bottom));
    .b-button {
      position: fixed;
      bottom: 0;
      padding: 0.2rem 0.36rem;
      padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
      padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
      .b-text-color {
        color: #7c5a2f;
      }
    }
  }
}
</style>
